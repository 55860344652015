<template>
  <div :class="{ 'editing-campaign': this.editingCampaign }">
    <div class="container-fluid mt-5">

      <div class="row" v-if="!isEnabled">
        <div class="col-xxl-10 mx-auto">
          <div class="alert-container">
            <div class="col-lg-12 alert alert-danger" role="alert">
              {{ this.$t('auth.accountisnotverified_nocampaigns') }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div :class="this.editingCampaign ? 'col-md-4 col-xxl-4 offset-xxl-1' : 'col-md-8 col-xxl-7 offset-xxl-1'">
          <div class="row col-stats">

            <div class="col-md-6 col-xl-3 mb-3 col-hidden" :class="{ 'col-show': !editingCampaign }">
              <div class="card">
                <div class="card-body card-top d-flex align-items-center">
                  <div class="d-flex align-items-center justify-content-center rounded stat-icon stat-icon-fourth-bg">
                    <img src="../assets/img/lightbulb.svg" alt="Light bulb" />
                  </div>
                  <div class="ms-3 card-title">
                    <small style="font-size: 12px">
                      {{ $t('general.activeads') }}
                    </small>
                    <h5 class="fw-bold mb-1">{{ activatedOffers }}</h5>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- <div class="col-md-6 col-xl-3 mb-3 col-hidden" :class="{ 'col-show': !editingCampaign }">
              <div class="card">
                <div class="card-body card-top d-flex align-items-center">
                  <div class="d-flex align-items-center justify-content-center rounded stat-icon stat-icon-third-bg">
                    <img src="../assets/img/star.svg" alt="Star" />
                  </div>
                  <div class="ms-3 card-title">
                    <small style="font-size: 12px">
                      {{ $t('general.mostpopularad') }}
                    </small>
                    <h5 class="fw-bold mb-1">{{ activatedOffers }}</h5>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-md-6 col-xl-3 mb-3 col-hidden" :class="{ 'col-show': !editingCampaign }">
              <div class="card">
                <div class="card-body card-top d-flex align-items-center">
                  <div class="d-flex align-items-center justify-content-center rounded stat-icon stat-icon-second-bg">
                    <img src="../assets/img/wavy-check.svg" alt="Checkmark" />
                  </div>
                  <div class="ms-3 card-title">
                    <small style="font-size: 12px">
                      {{ $t('general.totalinteractions') }}
                    </small>
                    <h5 class="fw-bold mb-1">{{ sentOffers }}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div :class="this.editingCampaign ? 'col-12' : 'col-md-6 col-xl-3'" class="mb-3 col-hidden col-show">
              <div class="card">
                <div class="card-body card-top d-flex align-items-center">
                  <div class="d-flex align-items-center justify-content-center rounded stat-icon stat-icon-first-bg">
                    <img src="../assets/img/wavy-check.svg" alt="Checkmark" />
                  </div>
                  <div class="ms-3 card-title">
                    <small style="font-size: 12px">
                      {{ $t('general.totalTargets') }}
                    </small>

                    <h5 class="fw-bold mb-1">{{ totalTargets }}</h5>

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="card p-3">
            <div class="card-body bg-gray rounded overflow-hidden">
              <div class="d-flex flex-column mt-3">
                <h5 class="card-title me-auto py-1">Översikt</h5>
                <div v-show="!editingCampaign">
                  <p class="m-1 me-3 d-inline-block align-middle">
                    {{ $t('general.addnewcampaign') }}
                  </p>

                  <div class="d-inline-block align-middle">
                    <Button @click="onCreate()" type="button" :loading="isLoading"
                      class="btn btn-primary d-flex justify-content-center align-items-center p-0"
                      style="height:34px;width:34px;">
                      <span class="fa fa-plus"></span>
                    </Button>
                  </div>
                </div>
              </div>

              <div class="mt-3" v-if="campaigns && campaigns.length == 0">
                {{ $t('general.youhavenocampaigns') }}
              </div>
              <table class="table table-hover mt-4" v-else-if="campaigns && campaigns.length > 0">
                <thead>
                  <tr>
                    <th scope="col" style="width:80px;">{{ $t('general.active') }}</th>
                    <th scope="col">{{ $t('general.title') }}</th>
                    <th scope="col" class="d-none" style="width:140px;" :class="{ 'd-sm-table-cell': !editingCampaign }">
                      {{ $t('general.totals') }}
                    </th>
                    <th scope="col" class="d-none" :class="{ 'd-sm-table-cell': !editingCampaign }">
                      {{ $t('general.total_value') }}
                    </th>
                    <th scope="col" class="d-none" :class="{ 'd-sm-table-cell': !editingCampaign }">
                      {{ $t('general.from') }} - {{ $t('general.until') }}
                    </th>
                    <th scope="col">
                      {{ $t('general.options') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- data-bs-toggle="modal" data-bs-target="#editCampaignModal" -->
                  <tr v-for="{ id, title, active, startDate, endDate, totalBooked = 0, totalAvailable = 0, totalValue = 0 } in campaigns" :key="id"
                    :class="{ 'active': this.editingId == id }" :on-load="loadTooltips()"
                    @mouseover="campaignHover = id" @mouseleave="campaignHover = false">
                    <td scope="row">
                      <input class="form-check-input" type="checkbox" value=""
                        @change="(event) => activeClick(id, event)" :checked="active == true ? true : false" />
                    </td>
                    <td class="text-truncate">{{ title }}</td>
                    <td class="text-truncate d-none" :class="{ 'd-sm-table-cell': !editingCampaign }">{{ totalBooked }} / {{ totalAvailable }}</td>
                    <td class="text-truncate d-none" :class="{ 'd-sm-table-cell': !editingCampaign }">{{ totalValue }}kr</td>
                    <!-- <td class="text-truncate d-none position-relative" :class="{ 'd-lg-table-cell': !editingCampaign }">
                      <div class="content-fade-right"></div>
                      <span v-if="description.length > 32" class="d-block postload-tooltip" data-bs-toggle="tooltip"
                        data-bs-placement="bottom" :title="description">{{ description }}</span>
                      <span v-else>{{ description }}</span>
                    </td> -->

                    <td class="text-truncate d-none" :class="{ 'd-sm-table-cell': !editingCampaign }">
                      {{ timeDateConvert(startDate) }} -
                      {{ timeDateConvert(endDate) }}
                    </td>
                    <td class="text-truncate text-primary text-end pe-1 text-center">
                      <span class="mx-2" data-bs-toggle="modal" data-bs-target="#campaignOrdersModal" @click="showModal(id)"><i class="fa-solid fa-line-chart"></i></span>
                      <span class="mx-2" @click="copy(id)"><i class="fa-solid fa-copy"></i></span>
                      <span class="mx-2" @click="onEdit($event, id)"><i class="fa-solid fa-pen"></i></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" v-else>
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4 col-xl-auto mt-5 pe-xl-5 mb-5">
          <div class="position-relative campaign-view-parent" ref="campaignContent">
            <div class="card mx-auto mx-md-0 campaign-view" v-if="!editingCampaign">
              <div v-if="campaignHover" class="campaign-details">
                <div class="campaign-image" :style="{
                  'background-image': `url(${campaignPreviewer.photoData})`,
                }"></div>
                <div class="campaign-text">
                  <div class="campaign-title">
                    {{ campaignPreviewer.title }}
                  </div>
                  <div class="campaign-discount">
                    {{ $t('general.yourdiscount') }}:
                    <b v-if="campaignPreviewer.discount >= 0">{{ campaignPreviewer.discount * 100 }}%</b>
                  </div>
                  <div class="campaign-description">
                    {{ campaignPreviewer.description }}
                  </div>
                  <!-- <div class="campaign-barcode text-center" v-if="campaignPreviewer.barcode">
                    <img alt="Barcode Generator TEC-IT"
                      :src="`https://barcode.tec-it.com/barcode.ashx?data=${campaignPreviewer.barcode}&code=EANUCC128&translate-esc=true`" />
                  </div> -->
                </div>
              </div>
              <div v-else class="campaign-details">
                <div class="campaign-image bg-light" style="background-size: 80%"></div>
                <div class="campaign-text">
                  <div class="campaign-title"></div>
                  <div class="campaign-discount"></div>
                  <div class="campaign-description"></div>
                  <!-- <div class="campaign-barcode text-center"> -->
                    <!-- insert your custom barcode setting your data in the GET parameter "data" -->
                    <!-- <img alt="Barcode Generator TEC-IT"
                      src="https://barcode.tec-it.com/barcode.ashx?data=payl&code=EANUCC128&translate-esc=true" />
                  </div> -->
                </div>
              </div>
            </div>
            <div v-else class="card mx-auto mx-md-0 campaign-view"
              :class="editCampaignDarkmode ? 'campaign-darkmode' : 'campaign-lightmode'">
              <div class="campaign-details">
                <div @click="selectImage">
                  <div class="campaign-image bg-light" style="background-size: 80%"
                    v-if="previewImage && previewImage.length <= 1"></div>
                  <div class="campaign-image" :style="{ 'background-image': `url(${previewImage})`, }" v-else></div>
                </div>

                <div class="campaign-text">
                  <div class="mb-2">
                    <input type="text" class="form-control fw-bold" :placeholder="$t('general.title')"
                      v-model="editCampaignData.title" :class="{ 'is-invalid': !isRequired.title }" />
                    <label style="font-size: 0.8rem" class="invalid-feedback" v-if="!isRequired.title">
                      {{ $t('general.title_required') }}
                    </label>
                  </div>

                  <div class="mb-2">
                    <textarea class="form-control" :placeholder="$t('general.description')" rows="4"
                      v-model="editCampaignData.description"
                      :class="{ 'is-invalid': !isRequired.description }"></textarea>
                    <label style="font-size: 0.8rem" class="invalid-feedback" v-if="!isRequired.description">
                      {{ $t('general.description_required') }}
                    </label>
                  </div>

                  <div class="mb-2">
                    <textarea class="form-control" :placeholder="$t('general.smsText')" rows="4"
                      v-model="editCampaignData.smsText" :class="{ 'is-invalid': !isRequired.smsText }"
                      ></textarea>
                    <label style="font-size: 0.8rem" class="invalid-feedback" v-if="!isRequired.smsText">
                      {{ $t('general.sms_required') }}
                    </label>
                    <div class="mt-2"><b>Kostnad per SMS: {{ cost }} kr</b></div>
                  </div>

                  <!--<div class="input-group-delete mb-2" :class="{ 'input-enabled': !editCampaignCode }">
                    <div class="position-relative">
                      <input type="text" class="form-control pe-5" :placeholder="$t('general.campaigncode')"
                        ref="editCampaignCode" v-model="editCampaignData.barcode" />
                      <button type="button"
                        class="btn btn-input-delete h-100 position-absolute top-50 end-0 translate-middle-y"
                        style="z-index:10" @click="editCampaignCode = !editCampaignCode;">
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </div>-->

                  <!--<div class="input-group-delete position-relative" :class="{ 'input-enabled': !editCampaignURL }">
                    <div class="position-relative">
                      <input type="text" class="form-control pe-5" :placeholder="$t('general.hyperlink')"
                        ref="editCampaignURL" v-model="editCampaignData.url" />
                      <button type="button"
                        class="btn btn-input-delete h-100 position-absolute top-50 end-0 translate-middle-y"
                        style="z-index:10" @click="editCampaignURL = !editCampaignURL;">
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
            <!--<div class="position-absolute top-100 start-50 bottom-0 translate-middle add-campaign-attr-rows w-100"
              v-show="this.editingCampaign">
              <div class="add-campaign-attr campaign-code" @click="editCampaignCode = !editCampaignCode;"
                :class="{ 'input-enabled': editCampaignCode }">
                <i class="fa-solid fa-plus me-2"></i>
                {{ $t('general.campaigncode') }}
              </div>
              <div class="add-campaign-attr campaign-url" @click="editCampaignURL = !editCampaignURL"
                :class="{ 'input-enabled': editCampaignURL }">
                <i class="fa-solid fa-plus me-2"></i>
                {{ $t('general.hyperlink') }}
              </div>
            </div> -->
          </div>
        </div>
        <div class="col mt-5 ps-0 position-relative edit-campaign-details mb-4">
          <div class="position-absolute top-0 left-0 px-4">
            <div v-if="this.sendingCampaignSMS">
              <SendCampaignSMS :campaign="this.editCampaignData" :campaignId="this.editingId" />
              <Button class="btn btn-dark w-100 d-block mt-2" :disabled="isLoading"
                @click="this.sendingCampaignSMS = false;">
                {{ $t('general.cancel') }}
              </Button>
            </div>
            <div :class="this.sendingCampaignSMS ? 'd-none' : ''">
              <div class="d-flex align-items-center">
                <h6 class="m-0">{{ creatingCampaign? $t('general.createnewcampaign'): $t('general.editcampaign') }}</h6>
                <div class="content-theme ms-auto">
                  <button class="campaign-darkmode-toggle" @click="editCampaignDarkmode = !editCampaignDarkmode"
                    :class="editCampaignDarkmode ? 'campaign-darkmode' : 'campaign-lightmode'">
                    <i class="fas fa-moon"></i>
                  </button>
                </div>
              </div>

              <div>
                <div class="mt-2 gap-2 d-flex">
                  <label class="form-check-label" for="campaignActive">
                    {{ $t('general.activate') }}
                  </label>
                  <input class="form-check-input" type="checkbox" id="campaignActive"
                    v-model="this.active"
                    :checked="this.active" />
                </div>
              </div>

              <div class="my-4">
                <label for="">{{ $t('general.chooseimageforcampaign') }} </label>
                <div class="input-group d-none">
                  <span class="input-group-text">Välj fil</span>
                  <input type="text" aria-label="" class="form-control" />
                </div>

                <input class="form-control pe-2" ref="fileInput" type="file" @input="pickFile"
                  accept="image/png, image/jpeg" :class="{ 'is-invalid': !isRequired.photoData }" />

                <label style="font-size: 0.8rem" class="invalid-feedback" v-if="!isRequired.photoData">
                  {{ $t('general.image_required') }}
                </label>
              </div>

              <div class="mb-4">
                <label>{{ $t('general.choosebookingperiod') }}</label>
                <Datepicker v-model="campaignDateRange" range multiCalendars preventMinMaxNavigation autoApply
                  :placeholder="$t('general.choosecampaignperiod')" :format="campaignDateRangeFormat"
                  :inputClassName="`form-control ps-4-5 ${!isRequired.startDate || !isRequired.endDate ? 'is-invalid' : ''}`"
                  menuClassName="datepicker-menu" :dayNames="[
                    $t('general.weekdays.short.monday'),
                    $t('general.weekdays.short.tuesday'),
                    $t('general.weekdays.short.wednesday'),
                    $t('general.weekdays.short.thursday'),
                    $t('general.weekdays.short.friday'),
                    $t('general.weekdays.short.saturday'),
                    $t('general.weekdays.short.sunday'),
                  ]" :yearRange="[new Date().getFullYear(), new Date().getFullYear() + 1]"
                  :enableTimePicker="true"></Datepicker>
                <label style="font-size: 0.8rem" class="text-danger"
                  v-if="!isRequired.startDate || !isRequired.endDate">
                  {{ $t('general.date_required') }}
                </label>
              </div>

              <div class="mb-4">
                <label>{{ $t('general.choosecollectionperiod') }}</label>
                <Datepicker v-model="campaignCollectionDateRange" range multiCalendars preventMinMaxNavigation autoApply
                  :placeholder="$t('general.choosecollectionperiod')" :format="campaignDateRangeFormat"
                  :inputClassName="`form-control ps-4-5 ${!isRequired.startDate || !isRequired.endDate ? 'is-invalid' : ''}`"
                  menuClassName="datepicker-menu" :dayNames="[
                    $t('general.weekdays.short.monday'),
                    $t('general.weekdays.short.tuesday'),
                    $t('general.weekdays.short.wednesday'),
                    $t('general.weekdays.short.thursday'),
                    $t('general.weekdays.short.friday'),
                    $t('general.weekdays.short.saturday'),
                    $t('general.weekdays.short.sunday'),
                  ]" :yearRange="[new Date().getFullYear(), new Date().getFullYear() + 1]"
                  :enableTimePicker="true"></Datepicker>
                <label style="font-size: 0.8rem" class="text-danger"
                  v-if="!isRequired.startDate || !isRequired.endDate">
                  {{ $t('general.date_required') }}
                </label>
              </div>

              <div class="mt-4 mb-4">
                <div class="mb-2">
                  <div class="accordion accordion-flush text-wrap" id="accordionFlushExample">
                    <!-- Loop through variants and create accordions -->
                    <div v-for="(variant, index) in variants" :key="index" class="accordion-item">
                      <div class="accordion-header d-flex justify-content-between" :id="'flush-heading' + index">
                        <!-- Accordion Title -->
                        <button class="accordion-button collapsed" type="button" :data-bs-toggle="'collapse'" :data-bs-target="'#flush-collapse' + index" :aria-expanded="isActiveVariant(index)" :aria-controls="'flush-collapse' + index">
                          {{ getAccordionTitle(variant.variant, index) }}
                        </button>
                        <!-- Minus icon to remove the variant -->
                        <button
                          v-if="variants.length > 1"
                          @click="removeVariant(index)"
                          class="btn btn-sm btn-danger align-self-center"
                        >
                          -
                        </button>
                      </div>
                      <div :id="'flush-collapse' + index" class="accordion-collapse collapse mt-4" :class="{ 'show': isActiveVariant(index) }" :aria-labelledby="'flush-heading' + index" data-bs-parent="#accordionFlushExample">
                        <!-- Form fields for each variant -->
                        <form>
                          <div class="mb-3">
                            <label for="sku" class="form-label">{{ $t('general.sku') }}</label>
                            <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.sku }" v-model="variant.sku" :placeholder="$t('general.sku_placeholder')" required>
                            <label style="font-size: 0.8rem" class="text-danger" v-if="!variant.errors.sku"> {{ variant.errors.sku }} </label>
                          </div>
                          <div class="mb-3">
                            <label for="variant" class="form-label">{{ $t('general.variant') }}</label>
                            <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.variant }" v-model="variant.variant" :placeholder="$t('general.variant_placeholder')" required>
                            <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.variant"> {{ variant.errors.variant }} </label>
                          </div>
                          <div class="mb-3">
                            <label for="unit" class="form-label">{{ $t('general.unit') }}</label>
                            <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.unit }" v-model="variant.unit" :placeholder="$t('general.unit_placeholder')" required>
                            <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.unit"> {{ variant.errors.unit }} </label>
                          </div>
                          <div class="row">
                            <div class="col-md-6 mb-3">
                              <label for="minPerCustomer" class="form-label">{{ $t('general.minPerCustomer') }}</label>
                              <input type="number" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.minOrders }" v-model="variant.minOrders" min="0" :max="variant.maxOrders" :placeholder="$t('general.minPerCustomer_placeholder')" required>
                              <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.minOrders"> {{ variant.errors.minOrders }} </label>
                            </div>
                            <div class="col-md-6 mb-3">
                              <label for="maxPerCustomer" class="form-label">{{ $t('general.maxPerCustomer') }}</label>
                              <input type="number" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.maxOrders }" v-model="variant.maxOrders" :min="variant.minOrders" :max="variant.maxDiscountQuantity" :placeholder="$t('general.maxPerCustomer_placeholder')" required>
                              <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.maxOrders"> {{ variant.errors.maxOrders }} </label>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="maxDiscountQuantity" class="form-label">{{ $t('general.availableQuantity') }}</label>
                            <input type="number" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.maxDiscountQuantity }" v-model="variant.maxDiscountQuantity" :placeholder="$t('general.availableQuantity_placeholder')" required>
                              <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.maxDiscountQuantity"> {{ variant.errors.maxDiscountQuantity }} </label>
                          </div>
                          <div class="row ">
                            <div class="col-md-4 mb-3 align-self-end">
                              <label for="regularPrice" class="form-label">{{ $t('general.regularPrice') }}</label>
                              <input type="number" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.price }" v-model="variant.price" :placeholder="$t('general.regularPrice_placeholder')" required>
                              <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.price"> {{ variant.errors.price }} </label>
                            </div>
                            <div class="col-md-4 mb-3 align-self-end">
                              <label for="discountPrice" class="form-label">{{ $t('general.discountedPrice') }}</label>
                              <input type="number" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.discountedPrice }" v-model="variant.discountedPrice" :placeholder="$t('general.discountedPrice_placeholder')" required>
                              <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.discountedPrice"> {{ variant.errors.discountedPrice }} </label>
                            </div>
                            <div class="col-md-4 mb-3 align-self-end">
                              <label for="discountPrice" class="form-label">{{ $t('general.discountedPercentage') }}</label>
                              <input type="number" class="form-control form-control-sm" :class="{ 'is-invalid': variant.errors.percentage }" v-model="variant.percentage" :placeholder="$t('general.discountedPercentage_placeholder')" required>
                              <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.percentage"> {{ variant.errors.percentage }} </label>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="note" class="form-label">{{ $t('general.note') }}</label>
                            <input type="text" class="form-control form-control-sm" v-model="variant.note" :placeholder="$t('general.note_placeholder')">
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Add New Variant Button -->
                <button class="btn btn-primary mt-4" @click="addNewVariant">{{ $t('general.addNewVariant') }}</button>
              </div>

              <div class="d-flex mt-2 handle-btns gap-3">
                <button class="btn btn-dark w-50" v-if="this.creatingCampaign"
                  @click="this.editingCampaign = false; this.editingId = '';" :disabled="isLoading">{{
                    $t('general.cancel')
                  }}</button>
                <button class="btn btn-danger w-50" data-bs-toggle="modal" data-bs-target="#deleteCampaignModal"
                  :disabled="isLoading" v-else>
                  {{ $t('general.delete') }}
                </button>
                <Button class="btn btn-primary w-100" @click="validateCampaignInput" :disabled="isLoading"
                  :loading="savingChanges">
                  {{ creatingCampaign? $t('general.createcampaign'): $t('general.savechanges') }}
                </Button>
              </div>
              <button class="btn btn-dark d-block w-100 mt-2" v-if="!this.creatingCampaign" :disabled="isLoading"
                @click="this.editingCampaign = false; this.editingId = '';">{{ $t('general.cancel') }}</button>

              <span :data-bs-toggle="!this.sendCampaignSMS_valid ? 'tooltip' : ''"
                :title="!this.isEnabled ? $t('auth.account_must_verified') : (!this.sendCampaignSMS_valid ? $t('general.campaign_must_activate') : '')">
                <Button class="btn btn-warning w-100 d-block mt-2" v-if="!this.creatingCampaign"
                  :disabled="isLoading || !sendCampaignSMS_valid || !isEnabled"
                  @click="this.sendingCampaignSMS = true;">
                  {{ $t('general.sendcampaign_via_sms') }}
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />

    <div class="modal fade" id="deleteCampaignModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <h1 class="modal-title fs-5 fw-bold mb-2">Är du säker?</h1>
            <p>Åtgärgen går inte att ångra.</p>

            <div class="mt-2">
              <Button type="button" class="btn btn-danger me-2" @click="onDelete(this.editingId)"
                :loading="isLoading">{{ $t('general.delete') }}</Button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                {{ $t('general.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Modal v-show="isModalVisible" @close="closeModal" id="campaignOrdersModal"> -->
    <div class="modal fade" id="campaignOrdersModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content"  v-if="selectedCampaign">
          <div class="modal-header">
            <h5 class="modal-title">Orders for "{{ selectedCampaign.title }}"</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="ordersPlaced">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Telefonnummer</th>
                    <th v-for="variant in uniqueVariants" :key="variant">{{ this.getVariantName(variant) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in ordersPlaced" :key="order.number">
                    <td>{{ order.number }}</td>
                    <td v-for="variant in uniqueVariants" :key="variant">
                      {{ getOrderQuantity(order, variant) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="download">Download</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // useLoadCampaigns,
  updateCampaign,
  deleteCampaign,
  UpdateActiveCampaign,
  auth,
  getMerchantUserProfile,
  // useLoadTarget,
  createCampaignDos,
  usersCollection,
  getCampaign,
  getOrdersSortedByPhone,
  // getOrdersByCampaignId
} from '@/firebase'
// import { useRouter } from "vue-router";
import SendCampaignSMS from './campaigns/SendSMS.vue'
import Button from './misc/Button.vue'
import { Tooltip, Modal } from 'bootstrap'
import { toast } from 'vue3-toastify';
import { count } from "sms-length";

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    Button,
    Datepicker,
    SendCampaignSMS
  },
  props: {
    msg: String,
  },
  data() {
    return {
      search: '',
      sortType: 'ASC',
      isEnabled: false,
      isLoading: true,
      savingChanges: false,
      campaignHover: false,
      userRole: '',
      userUid: '',
      viewPreview: true,
      showMessage: false,
      alertMessage: { message: '', error: false },
      campaigns: null,
      totalTargets: 0,
      activeCampaigns: 0,
      sentOffers: 0,
      activatedOffers: 0,
      editingCampaign: false,
      creatingCampaign: false,
      editCampaignCode: false,
      // editCampaignURL: false,
      editCampaignDarkmode: false,
      campaignDateRange: '',
      campaignCollectionDateRange: '',
      active: true,
      variants: [
        {
          sku: '',
          variant: '',
          unit: '',
          minOrders: null,
          maxOrders: null,
          maxDiscountQuantity: null,
          price: null,
          discountedPrice: null,
          percentage: null,
          note: '',
          errors: {
            sku: null,
            variant: null,
            unit: null,
            minOrders: null,
            maxOrders: null,
            maxDiscountQuantity: null,
            price: null,
            discountedPrice: null,
            percentage: null,
          }
        }
      ],
      activeVariantIndex: 0,
      isRequired: {
        title: true,
        description: true,
        startDate: true,
        endDate: true,
        photoData: true,
        smsText: true
      },
      editingId: '',
      editingDiscount: '',
      smsText: '',
      previewImage: '',
      editCampaignData: this.emptyCampaignData(),
      sendingCampaignSMS: false,
      sendCampaignSMS_valid: false,

      isModalVisible: false,
      selectedCampaign: null,
      ordersPlaced: []
    }
  },
  setup() {
    const campaignDateRangeFormat = (date) => {
      const startDate = date[0];
      const endDate = date[1];

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
      };

      const formattedStartDate = startDate.toLocaleString('sv-SE', options);
      const formattedEndDate = endDate.toLocaleString('sv-SE', options);

      return `${formattedStartDate} - ${formattedEndDate}`;    }

    const campaignDateFormat = (date) => {
      return `${date[0].getFullYear()}/${date[0].getMonth() + 1}/${date[0].getDate()}}`
    }

    return { campaignDateRangeFormat, campaignDateFormat }
  },
  // setup() {
  //   // const campaigns = useLoadCampaigns();
  //   // const articles = useLoadTarget();
  //   return { campaigns, deleteCampaign, router };
  // },
  async created() {
    try {
      this.isLoading = true
      const merchantDocPath = usersCollection.doc(auth.currentUser.uid)
      await merchantDocPath
        .collection('campaigns')
        .orderBy('created', 'desc')
        .onSnapshot(async (documents) => {
          this.campaigns = []
          this.activeCampaigns = 0;
          this.activatedOffers = 0;
          documents.forEach(async (doc) => {
            const data = doc.data();
            const orders = data.orders ?? {}
            let totalValue = 0;
            const totalAvailable = data.variants.reduce((a,v) => a + v.maxDiscountQuantity, 0)
            let totalBooked = 0;

            Object.keys(orders).map(k => {
              let totalQuantity = 0;
              orders[k].forEach(o => {
                let orderVariant = data.variants.find(v => v.sku == o.sku)
                totalQuantity += o.quantity;
                totalValue = totalValue + (o.quantity * orderVariant.discountedPrice)
              })
              totalBooked += totalQuantity;
            })    

            this.campaigns.push({
              id: doc.id,
              ...data,
              totalAvailable: totalAvailable,
              totalBooked: totalBooked,
              totalValue: totalValue,
            })
            if (doc.data().active) this.activeCampaigns++;
            if (doc.data().activatedOffers) this.activatedOffers += doc.data().activatedOffers;
          })
        })
      try {
        await merchantDocPath
          // .collection('campaigns')
          // .orderBy('created', 'desc')
          .collection('targets')
          .orderBy('optedIn', 'desc')
          .onSnapshot(async (documents) => {
            this.totalTargets = 0
            console.log(documents.length)
            documents.forEach((doc) => {
              console.log(doc)
              this.totalTargets += 1
            })
          })

          const merchantSnapshot =  await merchantDocPath.get()
          const {
            sentOffers,
            // activatedOffers,
          } = merchantSnapshot.data()

          this.sentOffers = sentOffers
          // this.activatedOffers = activatedOffers

        // await merchantDocPath
        //   .collection('targets')
        //   .orderBy('optedIn', 'asc')
        //   .onSnapshot(async (documents) => {
        //     console.log('documents')
        //     console.log(documents)
        //     this.totalTargets = documents.length
        //   })
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    } catch (error) {
      console.error(error)
      toast.error('Failed to load campaigns!');
      return (this.isLoading = false)
    }

    this.loadTooltips()
  },
  async mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })

    auth.currentUser.getIdTokenResult().then(({ claims }) => {
      this.userRole = claims.admin ? 'admin' : 'guest'
    })

    auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          this.userUid = user.uid
          const { enable } = await getMerchantUserProfile(user.uid)

          console.log('enable', enable)

          this.isLoading = false
          this.isEnabled = enable
        }
      } catch (error) {
        console.log('Could not find a store')
      }
    })
  },
  methods: {
    async showModal(id) {
      this.selectedCampaign = this.campaigns.find((campaign) => campaign.id === id);
      const orders = await getOrdersSortedByPhone( this.selectedCampaign.creator, id)
      this.ordersPlaced = !orders || orders.error ? [] : orders
    },
    async editCampaign(id, user) {
      this.resetCampaignInput();
      const campaign = await getCampaign(id, user);
      this.editCampaignData.title = campaign.title
      this.editCampaignData.description = campaign.description
      this.editCampaignData.startDate = this.timeConvert(campaign.startDate)
      this.editCampaignData.endDate = this.timeConvert(campaign.endDate)
      this.editCampaignData.collectionStartDate = this.timeConvert(campaign.collectionStartDate)
      this.editCampaignData.collectionEndDate = this.timeConvert(campaign.collectionEndDate)
      this.editingDiscount = this.editCampaignData.discount;
      // this.editCampaignData.barcode = campaign.barcode
      this.previewImage = campaign.photoData
      this.editCampaignData.photoData = campaign.photoData
      this.editCampaignData.oldPhotoData = campaign.photoData
      this.editCampaignData.active = campaign.active;
      // this.editCampaignData.url = campaign.url;
      this.editCampaignData.smsText = campaign.smsText || '';

      this.campaignDateRange = [this.editCampaignData.startDate, this.editCampaignData.endDate];
      this.campaignCollectionDateRange = [this.editCampaignData.collectionStartDate, this.editCampaignData.collectionEndDate];
      this.variants = campaign.variants.map(v => ({...v, errors: {
            sku: null,
            variant: null,
            unit: null,
            minOrders: null,
            maxOrders: null,
            maxDiscountQuantity: null,
            price: null,
            discountedPrice: null,
            percentage: null,
          }}))

      this.active = campaign.active

      // this.editCampaignCode = campaign.barcode && campaign.barcode.length > 0 ? true : false;
      // this.editCampaignURL = campaign.url && campaign.url.length > 0 ? true : false;

      this.sendCampaignSMSValidate()

      this.isLoading = false;
    },
    async copy(id) {
      const currentURL = `${window.location.protocol}//${window.location.host}`;
      const subscriptionLink = `${currentURL}/offer/general/${id}`;
      navigator.clipboard.writeText(subscriptionLink);

      toast.success('Copied to clipboard')
    },
    resetCampaignInput() {
      this.sendingCampaignSMS = false;
      this.editingDiscount = '';
      this.smsText = '';
      this.campaignDateRange = '';
      this.campaignCollectionDateRange = '';
      this.editCampaignCode = false;
      // this.editCampaignURL = false;
      this.$refs.fileInput.value = null;
      Object.keys(this.isRequired).forEach(v => this.isRequired[v] = true)
      this.editCampaignData = this.emptyCampaignData();
      this.variants = [
        {
          sku: '',
          variant: '',
          unit: '',
          minOrders: null,
          maxOrders: null,
          maxDiscountQuantity: null,
          price: null,
          discountedPrice: null,
          percentage: null,
          note: '',
          errors: {
            sku: null,
            variant: null,
            unit: null,
            minOrders: null,
            maxOrders: null,
            maxDiscountQuantity: null,
            price: null,
            discountedPrice: null,
            percentage: null,
          }
        }
      ]
    },
    emptyCampaignData() {
      return {
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        smsText: '',
        // barcode: '',
        // barcodeType: 'ean',
        photoData: null,
        oldPhotoData: null,
        active: false,
        // url: '',
      };
    },
    selectImage() {
      this.$refs.fileInput.click()
    },
    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader()
        reader.onload = (e) => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
        this.editCampaignData.photoData = file[0]
      }
    },
    loadTooltips() {
      Array.from(
        document.querySelectorAll(
          '.postload-tooltip[data-bs-toggle="tooltip"]:not(.tooltip-loaded)'
        )
      ).forEach(function (tooltipNode) {
        tooltipNode.classList.add('tooltip-loaded')
        new Tooltip(tooltipNode)
      })
    },
    onModuleClose() {
      this.viewPreview = !this.viewPreview
      console.log('ClosedonClose')
    },
    async onDelete(id) {
      this.isLoading = true

      const delResult = await deleteCampaign(id)
      Modal.getInstance(document.getElementById('deleteCampaignModal')).hide();
      delResult.error ? toast.error(delResult.message) : toast.success(delResult.message);

      this.campaignHover = false
      this.editingCampaign = false
      this.isLoading = false
    },
    async activeClick(id, event) {
      this.isLoading = true

      const value = event.target.checked
      await UpdateActiveCampaign(id, { active: value })

      this.isLoading = false
    },
    async sortedItems(sortType) {
      if (sortType === 'ASC') {
        this.sortType = 'DESC'
        this.campaigns = await this.campaigns.sort((a, b) => {
          return a.created - b.created
        })
      } else {
        this.sortType = 'ASC'
        this.campaigns = this.campaigns.sort((a, b) => {
          return b.created - a.created
        })
      }
    },
    async setMessage() {
      this.showMessage = true
      return setTimeout(() => {
        return (this.showMessage = false)
      }, 2000)
    },
    timeConvert(time) {
      // change to time._seconds if loading from backend
      var date = new Date(time.seconds * 1000)

      var myDateString =
        date.getFullYear() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)

      return myDateString
    },
    timeDateConvert(time) {
      // Change to time._seconds if loading from the backend
      const date = new Date(time.seconds * 1000);

      const myDateString =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2);

      return myDateString;
    },
    async onCreate() {
      this.isLoading = true;
      this.editingCampaign = true;
      this.creatingCampaign = true;
      this.editingId = '';

      this.previewImage = '-';
      await this.resetCampaignInput();

      this.isLoading = false;
    },
    onEdit(event, id) {
      if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'BUTTON') {
        this.isLoading = true;
        if (this.editingId == id) {
          this.editingCampaign = false;
          this.editingId = '';
        } else {
          this.editingCampaign = true;
          this.editingId = id;
        }
        this.creatingCampaign = false;
        this.previewImage = this.campaignPreviewer.photoData

        this.editCampaign(id, this.userUid);
        this.$refs.campaignContent.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onHover(event, id) {
      if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'BUTTON') {
        this.$refs.editModal.showModal(id)
      }
    },
    dateToYYYYMD(date) {
      var d = date.getDate();
      var m = date.getMonth() + 1;
      var y = date.getFullYear();

      return y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
    },
    validateCampaignInput() {
      let formInvalid = false;

      this.editCampaignData.startDate = this.campaignDateRange && this.campaignDateRange.length > 0 ? this.campaignDateRange[0] : '';
      this.editCampaignData.endDate = this.campaignDateRange && this.campaignDateRange.length > 0 ? this.campaignDateRange[1] : '';

      this.editCampaignData.collectionStartDate = this.campaignCollectionDateRange && this.campaignCollectionDateRange.length > 0 ? this.campaignCollectionDateRange[0]: '';
      this.editCampaignData.collectionEndDate = this.campaignCollectionDateRange && this.campaignCollectionDateRange.length > 0 ? this.campaignCollectionDateRange[1]: '';

      const todayDate = new Date()
      const tomorrowDate = new Date(todayDate.setDate(todayDate.getDate() + 1))
      // const tempEndDate = new Date(this.editCampaignData.endDate)

      this.editCampaignData.startDate === '' ? (this.editCampaignData.startDate = this.dateToYYYYMD(tomorrowDate)) : this.editCampaignData.startDate
      // Validate details
      this.editCampaignData.title
        ? (this.isRequired.title = true)
        : (this.isRequired.title = false)

      this.editCampaignData.description
        ? (this.isRequired.description = true)
        : (this.isRequired.description = false)

        this.editCampaignData.smsText
        ? (this.isRequired.smsText = true)
        : (this.isRequired.smsText = false)

      // new Date(this.editCampaignData.startDate).getDate() >= new Date().getDate()
      //   ? (this.isRequired.startDate = true)
      //   : (this.isRequired.startDate = false)

      // tempEndDate !== '' &&
      //   tempEndDate > new Date(this.editCampaignData.startDate) &&
      //   tempEndDate > todayDate
      //   ? (this.isRequired.endDate = true)
      //   : (this.isRequired.endDate = false)

      this.editCampaignData.photoData
        ? (this.isRequired.photoData = true)
        : (this.isRequired.photoData = false)

      if(Object.values(this.isRequired).every((item) => item !== true)) {
        formInvalid = true
      }

      // validate variants
      this.variants = this.variants.map(variant => {
        const {errors} = variant

        variant.sku
          ? (errors.sku = null)
          : (errors.sku = 'Fältet är obligatoriskt')

        variant.variant
          ? (errors.variant = null)
          : (errors.variant = 'Fältet är obligatoriskt')

          variant.unit
            ? (errors.unit = null)
            : (errors.unit = 'Fältet är obligatoriskt')

          variant.minOrders !== null
          && variant.minOrders <= variant.maxOrders 
          && variant.minOrders <= variant.maxDiscountQuantity
            ? (errors.minOrders = null)
            : (errors.minOrders = 'Fältet är obligatoriskt eller fel format är angivet')

          variant.maxOrders
          && variant.maxOrders >= variant.minOrders 
          && variant.maxOrders <= variant.maxDiscountQuantity
            ? (errors.maxOrders = null)
            : (errors.maxOrders = 'Fältet är obligatoriskt eller fel format är angivet')

          variant.maxDiscountQuantity
          && variant.maxDiscountQuantity >= variant.minOrders 
          && variant.maxDiscountQuantity >= variant.maxOrders
            ? (errors.maxDiscountQuantity = null)
            : (errors.maxDiscountQuantity = 'Fältet är obligatoriskt eller fel format är angivet')

          variant.price
          && variant.price >= variant.discountedPrice 
            ? (errors.price = null)
            : (errors.price = 'Fältet är obligatoriskt eller fel format är angivet')

          variant.discountedPrice
          && variant.discountedPrice <= variant.price 
            ? (errors.discountedPrice = null)
            : (errors.discountedPrice = 'Fältet är obligatoriskt eller fel format är angivet')

          variant.percentage !== null
          && variant.percentage <= 100 && variant.percentage >=0
            ? (errors.percentage = null)
            : (errors.percentage = 'Fältet är obligatoriskt eller fel format är angivet')

          if(Object.values(errors).some((item) => item !== null)) {
            formInvalid = true
          }

          return {...variant, errors}
      })

      // (this.editCampaignData.targetGroupId = "");

      if(!formInvalid) this.saveCampaign()

    },
    basicToast(res) {
      if (res.message) {
        res.error ? toast.error(res.message) : toast.success(res.message);
      } else {
        toast.info(res);
      }
    },
    async saveCampaign() {
      try{
        this.isLoading = true;
        this.savingChanges = true;

        // if (Object.values(this.isRequired).every((item) => item === true)) {
        this.editCampaignData.startDate = new Date(this.campaignDateRange[0]).getTime()
        this.editCampaignData.endDate = new Date(this.campaignDateRange[1]).getTime()
        this.editCampaignData.collectionStartDate = new Date(this.campaignCollectionDateRange[0]).getTime()
        this.editCampaignData.collectionEndDate = new Date(this.campaignCollectionDateRange[1]).getTime()
        this.editCampaignData.variants = this.variants.map(v => {
          const variant = {...v}
          delete variant.errors
          return variant;
        })
        this.editCampaignData.active = this.active
                // if (!this.editCampaignCode) this.editCampaignData.barcode = '';
        // if (!this.editCampaignURL) this.editCampaignData.url = '';
        const result = this.creatingCampaign ? await createCampaignDos(this.editCampaignData) : await updateCampaign(this.editingId, { ...this.editCampaignData, });

        const message = result.data || result.message || 'Done'
        this.basicToast(this.creatingCampaign ? message : result || message);

        if(this.creatingCampaign) {
          console.log('>> result', result)
          this.sendCampaignSMSValidate()
          this.editingCampaign = true;
          this.creatingCampaign = false;
          this.editingId = result.data.id
        } else {
          this.editingId = '';
          this.editingCampaign = false;
          this.creatingCampaign = false;
          this.resetCampaignInput();
        }
        // } else {
        //   alert('Du har inte fyllt i allt korrekt!')
        // }

        this.isLoading = false
        this.savingChanges = false;
      }catch(err){
        this.isLoading = false
        this.savingChanges = false
      }
    },
    sendCampaignSMSValidate() {
      // let startOfToday = new Date()
      // startOfToday.setHours(0,0,0,0)
      // const start = new Date(this.editCampaignData.startDate).getTime()
      // const end = new Date(this.editCampaignData.endDate).getTime()
      console.log('>>>> here')
      this.sendCampaignSMS_valid = true // (startOfToday > start && startOfToday < end) && this.editCampaignData.active
    },
    addNewVariant() {
      // Add a new empty variant to the variants array
      this.variants.push({
        sku: '',
        variant: '',
        unit: '',
        minOrders: null,
        maxOrders: null,
        maxDiscountQuantity: null,
        price: null,
        discountedPrice: null,
        errors: {
          sku: null,
          variant: null,
          unit: null,
          minOrders: null,
          maxOrders: null,
          maxDiscountQuantity: null,
          price: null,
          discountedPrice: null,
          percentage: null,
        }
      });

      // Automatically open the newly added variant accordion
      this.activeVariantIndex = this.variants.length - 1;
    },
    removeVariant(index) {
      if (!this.isLastVariant) {
        // Remove the variant at the given index
        this.variants.splice(index, 1);

        // Adjust activeVariantIndex if needed after removing the variant
        if (index <= this.activeVariantIndex) {
          this.activeVariantIndex -= 1;
        }
      }
    },
    getAccordionTitle(variantTitle, index) {
      if (variantTitle.trim() === '') {
        return 'Variant #' + (index + 1);
      } else {
        return '#' + (index + 1) + ' ' + variantTitle;
      }
    },
    isActiveVariant(index) {
      return index === this.activeVariantIndex;
    },
    download() {
      // Prepare the CSV data
      const csvContent = this.generateCSV();

      // Create a Blob object with the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      // Create a temporary link and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'orders.csv';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    generateCSV() {
      // Extract all unique variants (skus)
      const uniqueVariants = [
        ...new Set(
          this.ordersPlaced?.reduce((variants, order) => {
            order.orders.forEach((item) => variants.push(item.sku));
            return variants;
          }, [])
        ),
      ];

      // Create CSV header
      let csvContent = 'phone number,' + uniqueVariants.join(',') + '\n';

      // Generate CSV rows
      this.ordersPlaced?.forEach((order) => {
        const row = [order.number];
        uniqueVariants.forEach((sku) => {
          const orderItem = order.orders.find((item) => item.sku === sku);
          row.push(orderItem ? orderItem.quantity : '');
        });
        csvContent += row.join(',') + '\n';
      });

      return csvContent;
    },
    getOrderQuantity(order, variant) {
      const orderItem = order.orders.find((item) => item.sku === variant);
      return orderItem ? orderItem.quantity : '';
    },
    getVariantName(sku) {
      return this.selectedCampaign.variants.find(v => v.sku === sku)?.variant ?? sku
    }
  },
  computed: {
    uniqueVariants() {
      return [
        ...new Set(
          this.ordersPlaced?.reduce((variants, order) => {
            order.orders.forEach((item) => {
              variants.push(item.sku)
            });
            return variants;
          }, [])
        ),
      ];
    },
    isLastVariant() {
      return this.variants.length === 1;
    },
    campaignPreviewer() {
      return this.campaignHover
        ? this.campaigns.find((x) => x.id === this.campaignHover)
        : console.log('Not hover')
    },
    cost() {
      // const linkLength = 'https://payl.io/g3Dda2E'.length
      const link = 'https://market.bokaklipp.se/offer/46701234567-VkJgsHRJylLCfx7ajREb'
      // const length = this.editCampaignData.smsText.length + 1 + linkLength
      const sms = count(`${this.editCampaignData.smsText} ${link}`);
      return sms.messages
    },
  },
}
</script>

<style scoped>
@import '../assets/css/dark.css';
@import '../assets/css/light.css';

* {
  --col-transition: 0.4s;
}

.row div[class^='col-'] {
  transition: width var(--col-transition) ease-in-out, margin-left var(--col-transition) ease-in-out;
}

.col-stats div[class^='col-'] {
  height: 70px;
}

@media(min-width:768px) {
  .col-hidden {
    position: absolute;
    opacity: 0;
    margin-top: -20px;
  }

  .col-hidden.col-show {
    animation-name: colShow;
    animation-duration: 0.15s;
    animation-delay: var(--col-transition);
    animation-fill-mode: forwards;
  }

  @keyframes colShow {
    0% {
      position: relative;
      opacity: 0;
      margin-top: -20px;
    }

    1% {
      opacity: 0;
      margin-top: -20px;
    }

    100% {
      position: relative;
      opacity: 1;
      margin-top: 0px;
    }
  }
}

.stat-icon {
  width: 50px;
  height: 50px;
}

.stat-icon img {
  max-width: 24px;
  max-height: 40px;
}

.stat-icon.stat-icon-first-bg {
  background-color: #2eb7e3;
}

.stat-icon.stat-icon-second-bg {
  background-color: #ffc01e;
}

.stat-icon.stat-icon-third-bg {
  background-color: #a039d1;
}

.stat-icon.stat-icon-fourth-bg {
  background-color: #39d197;
}

.fa-plus {
  color: #f5f5f5;
}

.campaign-view-parent {
  width: 351px;
  max-width: 100%;
}

.campaign-view {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  padding: 4px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px 0px #00000026;
}

.campaign-details {
  height: 100%;
  padding: 0px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.campaign-image {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-image: url('../assets/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.campaign-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 20px;
}

.campaign-title {
  font-weight: 900;
  font-size: 18px;
  height: 10%;
}

.campaign-discount {
  font-weight: 900;
  font-size: 14px;
  padding-top: 3px;
  height: 10%;
}

.campaign-description {
  font-size: 12px;
  padding-top: 10px;
  height: 55%;
}

.campaign-barcode {
  margin-top: 20px;
  justify-content: flex-end;
  height: 5%;
}

.campaign-barcode img {
  max-width: 80%;
  max-height: 55px;
}

.edit-campaign-details {
  opacity: 0;
  margin-left: 100px;
  /* overflow: scroll; */
  white-space: nowrap;
  transition: all var(--col-transition);
}

.editing-campaign .edit-campaign-details {
  opacity: 1;
  margin-left: 0px;
}

.edit-campaign-details .position-absolute {
  width: 360px;
  padding-bottom: 50px;
}

@media (max-width:1200px) {
  .edit-campaign-details .position-absolute {
    width: 100%;
  }
}


.card-top {
  border-radius: 10px;
  background: #fff;
  padding: 10px;
}

.ms-3 {
  margin: -5px auto;
}

.input-group span {
  background: #f5f5f5;
  border-radius: 3.30208px 0px 0px 3.30208px;
}

.handle-btns .btn {
  border: 0px;
  color: #fff;
  border-radius: 3.30208px;
  letter-spacing: 0.11115px;
  font-weight: 400;
}

.campaign-darkmode-toggle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  background-color: var(--bg);
  transition: 0.3s ease-in-out;
}

.campaign-darkmode-toggle i {
  font-size: 25px;
  color: var(--bg-icon);
}

.campaign-darkmode {
  background-color: var(--bg);
  color: var(--text);
  transition: 0.2s ease-in-out;
}

.card.campaign-view {
  z-index: 2;
}

.add-campaign-attr {
  border: 2px dotted #dbdbdb;
  width: 100%;
  color: #dbdbdb;
  background-color: #fcfcfc;
  padding: 10px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;

  height: 63px;

  padding-top: 25px;
  margin-top: -70px;

  transition: background-color 0.1s, margin-top 0.3s;
  transition: all 0.3s;
}

.editing-campaign .add-campaign-attr {
  margin-top: -15px;
}

.add-campaign-attr.input-enabled {
  margin-top: -63px;
}

.add-campaign-attr:hover {
  background-color: #fff;
  color: #2eb7e3;
}

.add-campaign-attr .fa-plus {
  background-color: #dbdbdb;
  padding: 2px 0 0 2.5px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 14px;
  transition: all 0.1s;
}

.add-campaign-attr:hover .fa-plus {
  background-color: #2eb7e3;
}

.add-campaign-attr.campaign-code {
  z-index: 1;
  position: relative;
}

.campaign-details .form-control {
  background: var(--bg-input);
  color: var(--text);
  padding: 9px 10px 7px 10px;
  transition: 0.2s ease-in-out;
}

.campaign-details .form-control:focus {
  background: var(--bg-input-focus);
}

.campaign-details textarea.form-control {
  min-height: 30px !important;
  font-size: 14px;
  transition: all 0.2s, 0s height;
}

.campaign-details .input-group-delete {
  height: 40px;
  transition: height 0.3s, padding 0.3s !important;
}

.campaign-details .input-group-delete.input-enabled {
  height: 0;
  overflow: hidden;
}

.campaign-details .input-group-delete.input-enabled * {
  opacity: 0;
}


.btn-input-delete {
  background: transparent;
  color: #ccc;
}

.btn-input-delete:hover {
  color: #ec5b5d;
}

.btn-input-delete:active {
  color: #e42529;
}
.accordion-header:has(.accordion-button:not(.collapsed)),
.accordion-button:not(.collapsed) {
  background-color: #eee;
}
.accordion-button:focus {
  outline: none;
  box-shadow: none;
}
</style>